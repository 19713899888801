<template>
   <body class="text-center" style="background-image:url(assets/img/smartc3_bg.jpg); background-size:cover; font-family: 'Quicksand', sans-serif;">
    <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column" style="max-width: 42em;">
  <header class="masthead mb-auto">
    <div class="inner">
      <h3 class="masthead-brand"><a href="#"><img src="assets/img/SmartC3_logo_white.png"></a></h3>
      <nav class="nav nav-masthead justify-content-center">
        <a class="nav-link active" href="#">Inicio</a>
        <a class="nav-link" href="#">Actividades</a>
        <a class="nav-link" href="#">Docente</a>
      </nav>
    </div>
    
  </header>

  <div class="container" style="padding-top:25%">
    <h1 class="cover-heading text-white">Bienvenidos a SmartC3</h1>
    <p class="lead text-white">Una divertida manera de aprender programación</p>
    <p class="lead">
      <a href="#" @click.prevent="changeLanguage(language.code)" class="btn btn-warning mb-2 mr-2 btn-rounded" v-for="language in languages" :key="language.id">{{ language.name }} <i class="fa fa-sign-in"></i></a>
    </p>
  </div>
  <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
  <footer class="mt-5">
    <div class="inner">
      <p><a style="color:#FFFFFF" href="https://etciberoamerica.com/">ETC Iberoamérica</a></p>
    </div>
  </footer>
</div>
</body>
</template>

<script>
// @ is an alias to /src
/* eslint-disable */
export default {
  name: "Home",
  components: {},
  data() {
    return {
      languages: [],
    }
  },
  methods: {
    changeLanguage(locale) {
        localStorage.language = locale;
        if(!localStorage.tokenETCSmartC3)
        {
          router.push('/login');
          return
        }
        router.push('/worlds');
    },
  },
  mounted() {
    if(!localStorage.language)
      localStorage.language = window.navigator.language.split('-')[0]

    this.getLanguages()
  }
};
</script>
    <style>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const messages = {
    es: {
        text: {
            languageSpanish: 'Español',
            languageEnglish: 'Inglés',
            logout: 'Cerrar sesión',
            worldHeader: 'Elige un mundo',
            school: 'Colegio',
            userType: 'Entrar como',
            user: 'Usuario',
            password: 'Contraseña',
            forgot: '¿Olvidaste tu contraseña?',
            login: 'Iniciar sesión',
            loginText: 'Completa los campos para entrar.',
            placeholderUser: 'Usuario',
            placeholderPassword: 'Contraseña',
            buttonLogin: 'Entrar',
            buttonEnter: 'Entrar',
            placeText: 'Elige un lugar',
            levelText: 'Nivel',
            buttonTest: 'Probar',
            buttonEvaluate: 'Evaluar',
            buttonCode: 'Activar',
            placeholderCode: 'Escribe tu código de activación',
            codeErrorMessage: 'Debes escribir un código',
            modalCodeTitleText: 'Ingresa tu nuevo código de activación',
        },
    },

    en: {
        text: {
            languageSpanish: 'Spanish',
            languageEnglish: 'English',
            logout: 'Logout',
            worldHeader: 'Choose a world',
            school: 'School',
            userType: 'Login as',
            user: 'User',
            password: 'Password',
            forgot: 'Forgot password?',
            login: 'Log in',
            loginText: 'Complete the form to log in.',
            placeholderUser: 'Username',
            placeholderPassword: 'Password',
            buttonLogin: 'Log in',
            buttonEnter: 'Enter',
            placeText: 'Choose a place',
            levelText: 'Level',
            buttonTest: 'Test',
            buttonEvaluate: 'Evaluate',
            buttonCode: 'Activate',
            placeholderCode: 'Enter your activation code',
            codeErrorMessage: 'You must insert a code',
            modalCodeTitleText: 'Enter your new activation code',
        }
    }
};

export const i18n = new VueI18n({
    locale: window.navigator.language.split('-')[0],
    fallbackLocale: 'es',
    messages
})
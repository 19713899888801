/* eslint-disable */
const mainMixins = {
    data() {
        return {

        }
    },

    methods: {
        openLoading() {
            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
        },

        showMessage(title, message, icon, urlReturn, tipoMensaje) {
            Swal.fire({
                title,
                text: message,
                icon,
                onClose: () => {
                    if(urlReturn != '' && tipoMensaje == 1)
                        location.href = urlReturn
                        if(urlReturn != '' && tipoMensaje == 3)
                        location.reload()
                }
            })
        },

        async getWorlds() {
            await axios.get(apiETC + "api/smartc3/worlds")
            .then((response) => {
                Swal.close()
                this.worlds = response.data
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        async getSubworlds(world_id, grade_id, role_id) {
            await axios.get(apiETC + "api/smartc3/sub-worlds?world_id=" + world_id + '&grade_id=' + grade_id + '&role_id=' + role_id + '&school_id=' + localStorage.school_id)
            .then((response) => {
                Swal.close()
                this.subWorlds = response.data
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        async getEducationLevel() {
            await axios.get(apiETC + 'api/rpt-ethinking/json/educationLevel', { headers: clientAPI })
            .then( (response) => {
                this.educationLevel = response.data.options
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        catchError(error) {
            Swal.close()
            if(error.response.status == 401)
                router.replace('/login')
            if(error.response.status == 500 || error.response.status == 404 || error.response.status == 405){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.statusText + ' ' + error.response.data.message,
                })
            }
        },
        async getGrades() {
            this.openLoading()
            await axios.get(apiETC + 'api/rpt-ethinking/json/grades?idEduLevel=' + $("#level_id").val(), { headers: clientAPI })
            .then( (response) => {
                Swal.close()
                this.grades = response.data.options
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        async getGroups() {
            this.openLoading()
            await axios.get(apiETC + 'api/rpt-ethinking/json/groupsSmartC3?idGrade=' + $("#grade_id").val() + '&idPerson=' + localStorage.idPerson, { headers: clientAPI })
            .then( (response) => {
                Swal.close()
                this.groups = response.data.options
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        async getSchools() {
            await axios.get(apiETC + "api/smartc3/get-schools")
            .then((response) => {
                Swal.close()
                this.schools = response.data.colleges
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        async getSchoolsByAdvisor() {
            await axios.get(apiETC + "api/smartc3/get-schools-advisor?idPerson=" + localStorage.idPerson)
            .then((response) => {
                Swal.close()
                this.schools = response.data.colleges
            })
            .catch( error => {
                this.catchError(error)
            })
        },
        async getLanguages() {
            await axios.get(apiETC + "api/smartc3/languages")
            .then((response) => {
                Swal.close()
                this.languages = response.data.data
            })
            .catch( error => {
                this.catchError(error)
            })
        },
    }
}

export default mainMixins
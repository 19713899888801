import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import Swal from 'sweetalert2'
import mainMixins from './mainMixins.js'
import { i18n } from '@/plugins/i18n'

Vue.config.productionTip = false

Vue.mixin(mainMixins)

window.axios = axios
window.apiETC = 'https://sistema.etciberoamerica.com/'
// window.apiETC = 'http://apietc.loc/'
window.router = router
window.Fire = new Vue()
window.Swal = Swal
window.clientAPI = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZDM5ZmFjNmVhZWEwMTFiNjI5M2Y0MTJmMGU5ZjAyNjAwYTU2MDEwNzg2NGU0YzFkMzhhNjg5MTg3MTg1YWFmYzIwOGMyOTZjMTYxMTc1MTQiLCJpYXQiOjE3MTUxMDM0NzYsIm5iZiI6MTcxNTEwMzQ3NiwiZXhwIjoxNzQ2NjM5NDc2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.YJfrWBfaldY-hWmI5YZYaQZCSeiMCvtt8o9x0Dk-0rqafPL_0PUYL91oNGOqVMVhE7rwbbThkTUVL3QPFXrnBSH6ixBf_xWdyAcMK7WQ8zQpXwmVDB63512sjLF-94e0ODRFuCXH-mA8HHPvudGrYqp9Ik_YBjFCCXmUhBNztSEZ65geLyPKn11mR8dz-pPTAewlOE1mtBI-sB-0MndSqIvN7ccIcC3wYTO7FpSWxnQ1mtdJF41TgQrug2dN8xIIrdqmd9W5jLK6nFn_pvY1kuIPSG6NogtiLLmICO3u63e6Epu7dB4zVU7bkX2Nt-NnZ58_J3Hq1HIrvvzXfDj2g7DUdYlQoe92Y5lXmK8_A71cCfsSbgmqSf0GLUHOtmvt7qc8m93g4Dwg_h_reubP6tQbdg3mz-E54wQEqq20XCubTeNDHp_3zEfXH5aF87-N8cbHiF2KSk8xZEEeYVu0h_5lSwH50OX4LzNz3W9TDUIlvbLfaymKA2N8A1YHKAKsjuArVQv0ZJRLdVezVHX9YIWmexDyJIRSzvRNCsYKfyIt9XCDsa3nbtxDNROuMjTCya7_X5NED-TBKU4wGNRRKlaeYJUka_R4OKMvYbJM27oQo_UTj14bqVvZLTuBWTeuFhfSbuhJ0QSUVr2go4lZZTkpztJ9U7ka-zdXP9x7RAo'
  // Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOWU0MWM5ZWE5OGZjMzY4ODk5ZDBjYzViNWExMjg2MDM4NDZlNTZkNzc3M2Q2ZGU1OGRhYmNhZWM5Y2IxNmZiYWIzMzgyOWU4MDJlODViMWYiLCJpYXQiOjE3MTgxOTY3ODYuODI0MTQyLCJuYmYiOjE3MTgxOTY3ODYuODI0MTQ1LCJleHAiOjE3NDk3MzI3ODYuODE2MTU5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.iect33VpEFVBXxRsoy68rbfTEbazS_sxBwQFsfnM-PFiU0a8WttdlJl6SV1s-Al9wYZpWkzAUYgPbStG23jq5Hh7eHZ0Mew-B8eysgPMfden3OaKZPkOmwIwEBwZofgxbtPi7YrYMekZ7SRWKUs79C-yZTNw7CnEMTOJcrqFvL4KskOxtsxdsZlGL5psGeQb9a-RII7eU2ka7TrlodzyndNb2hLX3l6FkwU35h0iUgSE-yZfdCcQwCJ_oE-oO4UXJvo7WHqlRi-TwYTLeeXTPSrjktcvjfT-2M-qI4NLtHd6Le_oyH2om1T6CLYJBeXPGitTN9fvAUC0HObghIGcsMUdlh7eTz0iWBPRL5EdYpAiVvSP6a5TfTJzAN_Rx2XvdZ5VphYoP56C0HF4wopIHx1eGjvcewPQAhGDm74bUuTf0DuvnIXItsjdPSN2-3c0FQ69vhMU8gKnZ7iWWKZak7omXGWbvNWxOymU4OHpmGgTgDVLtUOYfyikeb5h3dwbYTLcWjW0jkRxspeIzGcLsqUQ7dqf1XhTLTDibETz821AE_pNt7RgLP2kjrS1Z34Aj3JxlfhntQQ7GLvxT0icWPRKShMwbsFQ_RlnpNTA5iSClykChrEPwffk4SL6axIUw4EXd738o3id9NhsfOjWLyQtoZJEPJK0g6DxwO-YIb8'
}

window.clientInternalAPI = {
  'Content-Type': 'application/json',
  Authorization: localStorage.tokenETC
}

window.hostname = location.hostname

Vue.config.ignoredElements = ['field','block','category','xml','mutation','value','sep']

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

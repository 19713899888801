import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    reportDetail: {},
    subWorlds: {},
    language: 'es',
  },
  mutations: {
    setReportDetail(state, subWorld) {
      state.reportDetail = subWorld
    },
    setSubWorlds(state, subWorlds) {
      state.subWorlds = subWorlds
    },
    setLanguage(state, language) {
      state.language = language
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    reportDetail: state => {
      return state.reportDetail
    },
    subWorlds: state => {
      return state.subWorlds
    },
    language: state => {
      return state.language
    },
  }
})
